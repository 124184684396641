import React, { Component, useState } from 'react'
import styled from 'styled-components'
import { Form, Input } from 'semantic-ui-react'

// Components
import ButtonBrochure from '../elements/ButtonBrochure'
import TitleDefault from 'components/elements/TitleDefault'

const StyledForm = styled(Form)`
  & label {
    color: ${(props) => props.theme.color.text.light};
  }
`

const FormInput = styled(Input)`
  & input {
    height: 45px;
    width: 420px;
    padding: 0px 10px;
    font-size: 15px;
    color: ${(props) => props.theme.color.text.black};

    @media (max-width: 991px) {
      width: 100%;
    }
  }
`

function StepFive({ values, handleChange, handleSubmit }) {
  const [isError, setError] = useState(false)

  function checkValues() {
    if (values.name !== '' && values.phone !== '' && values.company !== '') {
      handleSubmit({ values })
    } else {
      setError(true)
    }
  }

  return (
    <>
      <TitleDefault className="text-center text-white mb-5 pb-lg-4">
        Ja , ik wil deze korting!
      </TitleDefault>
      <StyledForm size="big" className="mb-4 d-flex">
        <div className="mx-auto">
          <label className="d-block text-center mb-5">
            Laat hieronder uw gegevens achter dan nemen
            <br />
            wij zo snel mogelijk contact op!
          </label>
          <Form.Field required error={isError}>
            <FormInput
              error={isError}
              placeholder="Naam"
              name="name"
              onChange={handleChange('name')}
              defaultValue={values.name}
              className="mb-3"
            />
          </Form.Field>
          <Form.Field required error={isError}>
            <FormInput
              error={isError}
              placeholder="Telefoonnummer"
              name="phone"
              onChange={handleChange('phone')}
              defaultValue={values.phone}
              className="mb-3"
            />
          </Form.Field>
          <Form.Field required error={isError}>
            <FormInput
              error={isError}
              placeholder="Bedrijfsnaam"
              name="company"
              onChange={handleChange('company')}
              defaultValue={values.company}
              className="mb-3"
            />
          </Form.Field>
        </div>
      </StyledForm>
      <div className="footer col-12 row mx-0 mb-4 pt-3 pt-lg-5">
        <div className="mx-auto d-flex">
          <ButtonBrochure className="mr-2" onClick={checkValues}>
            Verzenden
          </ButtonBrochure>
        </div>
      </div>
    </>
  )
}

export default StepFive
