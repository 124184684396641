/* eslint-disable react/no-array-index-key */
import React, { useState, useLayoutEffect } from 'react'
import Img from 'gatsby-image'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'
import ParseContent from 'components/shared/ParseContent'
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

const StyledImg = styled(Img)`
  position: absolute !important;
  height: 100%;
  width: 70%;
  right: 0;
  pointer-events: none;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`

const StyledParseContent = styled(ParseContent)`
  p {
    line-height: 22px;
  }
`

const StyledHero = styled.div`
  position: relative;
  height: 592px;
  z-index: 1;

  @media screen and (max-width: 991px) {
    height: unset;
  }
`

const HeroContent = styled(motion.div)`
  background-color: ${props => props.theme.color.face.light};
  height: 100%;
  border-top-right-radius: 600px;
  border-bottom-right-radius: 600px;
  padding: 80px 150px 0 20px;
  position: relative;
  right: 100px;

  @media screen and (max-width: 991px) {
    border-radius: 20px;
    padding: 80px 80px 80px 80px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  }

  @media screen and (max-width: 575px) {
    padding: 30px 30px 30px 30px;
  }

  h1, h2 {
    font-weight: ${props => props.theme.font.weight.xl};
    padding-bottom: 20px;

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

  p {
    font-size: ${props => props.theme.font.size.m};

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

  a {
    color: ${props => props.theme.color.text.contrast};

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  margin-top: 70px;
`

const BrandImg = styled(Img)`
  width: 90px;
`

const ImgContainer = styled.div`
  height: 90px;
`

const HeroRow = styled.div`
  @media screen and (max-width: 991px) {
    padding: 100px 20px 100px 20px;
  }
`

const HeroCopy = ({ hasOptional, className, content }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.5,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if(isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledHero ref={ref} className={`${className ? `${className}` : ``}`}>
      <StyledImg fluid={content.image.localFile.childImageSharp.fluid} alt="" />
      <div className="container h-100">
        <HeroRow className="row h-100">
          <HeroContent
            animate={isVisible || view ? { right: 0 } : { right: 100 }}
            transition={{ duration: 0.6, type: 'spring' }}
            className="col-lg-7 d-flex justify-content-center flex-wrap"
          >
            <div>
              {content.description ? (
                <StyledParseContent content={content.description} />
              ) : (
                <StyledParseContent content={content.text} />
              )}
             
              {content.link && (
                <StyledButtonDefault icon="arrow" to={content.link.url}>{content.link.title}</StyledButtonDefault>
              )}
              {content.relation && (
                <div className="row justify-content-center pt-5">
                  {content.relation.map((brand, index) => (
                    <CustomLink to={brand.path} key={index} className="col-6 col-sm-3 pb-4">
                      <ImgContainer className="d-flex justify-content-center align-items-center">
                        <BrandImg loading="eager" fadeIn={false} fluid={brand.acf.preview.image.localFile.childImageSharp.fluid} alt="" />
                      </ImgContainer>
                    </CustomLink>
                  ))}
                  {hasOptional && (
                    content.optional.map((c, index) => (
                      <CustomLink external newPage to={c.url} key={index} className="col-6 col-sm-3 pb-4">
                        <ImgContainer className="d-flex justify-content-center align-items-center">
                          <BrandImg loading="eager" fadeIn={false} fluid={c.image.localFile.childImageSharp.fluid} alt="" />
                        </ImgContainer>
                      </CustomLink>
                    ))
                  )}
                </div>
              )}
            </div>
          </HeroContent>
          <div className="col-lg-5" />
        </HeroRow>
      </div>
    </StyledHero>
  )
}

export default HeroCopy