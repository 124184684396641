/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'


const StyledButton = styled(motion.div)`
  position: relative;
  background: ${props => props.theme.color.gradient.main};
  border-radius: 29.5px;
  display: inline-block;

  ${props => props.small ? css`
    height: 30px;
  ` : css`
    height: 45px;
  `}
  
  & > a, & > button {
    color: ${props => props.theme.color.text.light};
    font-weight: ${props => props.theme.font.weight.xl};

    ${props => props.small ? css`
      padding: 5px 20px 5px 35px;
    ` : css`
      padding: 10px 50px;
    `}

    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.color.text.light};
    }
  }
`

const ButtonBrochure = ({ isCustom, children, className, onClick }) => (
  <StyledButton
    initial="rest"
    whileHover="hover"
    animate="rest"
    className={className}
  >
    {isCustom ? children : (
      <button onClick={onClick}>{children}</button>
    )}
  </StyledButton>
)

export default ButtonBrochure