import React, { Component, useState } from 'react'
import styled from 'styled-components'
import { Form, Input } from 'semantic-ui-react'

// Components
import ButtonBrochure from 'components/elements/ButtonBrochure'
import TitleDefault from 'components/elements/TitleDefault'

const Label = styled.label`
  color: ${(props) => props.theme.color.text.light};
`

const FormInput = styled(Input)`
  & input {
    height: 45px;
    width: 220px;
    padding: 0px 10px;
    font-size: 15px;
    color: ${(props) => props.theme.color.text.dark};
  }
`

function StepOne({ values, nextStep, handleChange }) {
  const [isError, setError] = useState(false)

  function checkValues() {
    if (values !== '') {
      nextStep()
    } else {
      setError(true)
    }
  }

  return (
    <>
      <TitleDefault className="text-center text-white mb-5 pb-lg-4">
        Bereken uw besparing
      </TitleDefault>
      <Form size="big" className="mb-4 text-center">
        <Form.Field required error={isError}>
          <Label className="mb-5">
            Hoeveel medewerkers hebben een mobiel abonnement?
          </Label>
          <FormInput
            type="number"
            placeholder="Aantal medewerkers"
            onChange={handleChange('employees')}
            defaultValue={values}
          />
        </Form.Field>
      </Form>
      <div className="footer col-12 row mx-0 mb-4 pt-3 pt-lg-5">
        <div className="mx-auto">
          <ButtonBrochure onClick={checkValues}>Volgende</ButtonBrochure>
        </div>
      </div>
    </>
  )
}

export default StepOne
