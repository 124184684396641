import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Radio } from 'semantic-ui-react'

// Components
import ButtonBrochure from '../elements/ButtonBrochure'
import TitleDefault from 'components/elements/TitleDefault'

const StyledForm = styled(Form)`
  & label {
    color: ${(props) => props.theme.color.text.light};
  }

  & .field {
    display: flex;
    align-items: center;
    & label {
      display: flex;
      align-items: center;
      margin: 0 auto 20px;
    }
    & input {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }
`

function StepTwo({ values, handleChange, nextStep, previousStep }) {
  const [isError, setError] = useState(false)

  function checkValues() {
    if (values !== '') {
      nextStep()
    } else {
      setError(true)
    }
  }

  return (
    <>
      <TitleDefault className="text-center text-white mb-5 pb-lg-4">
        Bereken uw besparing
      </TitleDefault>
      <StyledForm size="big" className="mb-4 d-flex">
        <Form.Group grouped required error={isError} className="mx-auto">
          <label className="d-block text-center mb-5">
            Hoeveel bespaar ik met
          </label>
          <Form.Field
            label="het verlengen van abonnementen"
            control="input"
            type="radio"
            name="gruop"
            checked={values === '60'}
            onChange={handleChange('savings')}
            defaultValue={values}
            value="60"
          />
          <Form.Field
            label="het afsluiten van nieuwe abonnementen"
            control="input"
            type="radio"
            name="gruop"
            checked={values === '80'}
            onChange={handleChange('savings')}
            defaultValue={values}
            value="80"
          />
        </Form.Group>
      </StyledForm>
      <div className="footer col-12 row mx-0 mb-4 pt-3 pt-lg-5">
        <div className="mx-auto d-flex flex-wrap">
          <ButtonBrochure
            className="mr-lg-2 mb-3 mb-lg-0 mx-auto mx-lg-0"
            onClick={previousStep}
          >
            Vorige
          </ButtonBrochure>
          <ButtonBrochure
            className="ml-lg-2 mx-auto mx-lg-0"
            onClick={checkValues}
          >
            Volgende
          </ButtonBrochure>
        </div>
      </div>
    </>
  )
}

export default StepTwo
