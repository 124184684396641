import React from "react";
import styled from "styled-components";

const Step = styled.div`
  &.active {
    & span {
      background: ${props => props.theme.color.gradient.secondary};
      color: ${(props) => props.theme.color.text.light};
    }
  }

  & span {
    background: ${props => props.theme.color.face.light};
    display: block;
    width: 45px;
    color: ${(props) => props.theme.color.text.dark};
    font-size: 26px;
    line-height: 45px;
    text-align: center;
    z-index: 1;
  }

  &.line {
    &::after {
      content: "";
      height: 3px;
      width: 100%;
      display: block;
      position: absolute;
      top: 50%;
      left: -50%;
      z-index: 0;
      background: ${props => props.theme.color.face.light};
    }
  }
`;

const StepsBar = ({ step }) => {
  return (
    <div className="row mb-5 pb-4">
      <div className="col-12 d-flex">
        <Step
          className={`col-lg-3 d-flex justify-content-center ${
            step === 1 ? "active" : ""
          }`}
        >
          <span>1</span>
        </Step>
        <Step
          className={`col-lg-3 d-flex justify-content-center line ${
            step === 2 ? "active" : ""
          }`}
        >
          <span>2</span>
        </Step>
        <Step className={`col-lg-3 d-flex justify-content-center line ${
            step === 3 ? "active" : ""
          }`}>
          <span>3</span>
        </Step>
        <Step className={`col-lg-3 d-flex justify-content-center line ${
            step === 4 ? "active" : ""
          }`}>
          <span>4</span>
        </Step>
      </div>
    </div>
  );
};

export default StepsBar;
