import React, { Component, useState } from 'react'
import styled from 'styled-components'
import { Form, Input } from 'semantic-ui-react'

// Components
import ButtonBrochure from '../elements/ButtonBrochure'
import TitleDefault from 'components/elements/TitleDefault'

const StyledForm = styled(Form)`
  & label {
    color: ${(props) => props.theme.light};
  }
`

const Saving = styled.h4`
  font-family: 'Oswald', sans-serif;
  color: ${(props) => props.theme.light};
  font-size: 40px;
`

function EndStep({ toStart }) {
  return (
    <>
      <StyledForm size="big" className="mb-4 d-flex">
        <div className="mx-auto">
          <TitleDefault className="text-center text-white mb-3">
            Bedankt voor uw enthousiasme!
          </TitleDefault>
          <label className="d-block text-center text-white mb-5">
            Breedband in Bedrijf neemt zo snel mogelijk contact op.
          </label>
        </div>
      </StyledForm>
      <div className="footer col-12 row mx-0 mb-4 pt-3 pt-lg-5">
        <div className="mx-auto d-flex">
          <ButtonBrochure className="mr-2" onClick={toStart}>
            Sluiten
          </ButtonBrochure>
        </div>
      </div>
    </>
  )
}

export default EndStep
