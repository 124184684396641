/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Breadcrumb from 'components/shared/Breadcrumb'
import Modal from 'components/elements/Modal'
import Content from 'components/shared/Content'
import Image from 'components/elements/Image'
import HeroCopy from 'components/shared/HeroCopy'
import SavingTool from 'components/SavingTool/SavingTool'


// Third Party
import styled from 'styled-components'

const StyledContent = styled(Content)`
  & h2 {
    font-weight: ${props => props.theme.font.weight.xl};
    margin-bottom: 20px;
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...VodafoneFrag
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, acf, yoast_meta: yoast },
  },
  pageContext
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <Hero left content={acf.banner} className="mb-lg-3" />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <section>
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-lg-6 pr-5">
              <StyledContent content={acf.brochure.description} />
            </div>
            <div className="col-2 d-none d-lg-block" />
            <div className="col-lg-4 pt-3 pt-lg-0">
              <Image src={acf.brochure.image} />
              <div className="pl-4 pl-lg-0">
                <Modal image={acf.brochure.image} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="besparing" className="color-background-dark">
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-10 mx-auto">
              <SavingTool />
            </div>
          </div>
        </div>
      </section>

      <HeroCopy className="mb-5" content={acf.content} />

    </Layout>
  )
}

export default PageTemplate
