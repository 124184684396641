// eslint-disable-next-line
import React, { Component, useState } from "react";

import StepsBar from './StepsBar/StepsBar'
import StepOne from "./StepOne"
import StepTwo from "./StepTwo"
import StepThree from "./StepThree"
import StepFour from "./StepFour"
import StepFive from './StepFive'
import EndStep from './EndStep'


class SavingTool extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    
    this.state = {
      step: 1,
      employees: '',
      savings: '',
      choice: '',
      amount: '',
      name: '',
      phone: '',
      company: '',
      value: '',
    };
  }

  setName = (name) => {
    this.setState((state) => ({
      ...state,
      name,
    }));
  };

  setPhone = (phone) => {
    this.setState((state) => ({
      ...state,
      phone,
    }));
  };

  setcompany = (company) => {
    this.setState((state) => ({
      ...state,
      company,
    }));
  };

  setEmployees = (employees) => {
    this.setState((state) => ({
      ...state,
      employees,
    }));
  };

  setSavings = (savings) => {
    this.setState((state) => ({
      ...state,
      [savings.name] : savings.value
    }));
  };

  setChoice = (choice) => {
    this.setState((state) => ({
      ...state,
      [choice.name] : choice.value
    }))
  };


  setAmount = (amount) => {
    this.setState((state) => ({
      ...state,
      amount,
    }))
  };
  
  toStart = () => {
    this.setState(state => ({
      ...state,
      step: 1,
    }))
  };

  previousStep = () => {
    this.setState(state => ({
      ...state,
      step: state.step - 1,
    }))
  };

  nextStep = () => {
    this.setState(state => ({
      ...state,
        step: state.step + 1,
      }))
  };

  handleChange = input => event => {
    this.setState({ 
      ...this.state,
      [input] : event.target.value 
    })
  }

  handleSubmit = async (values) => {
    try {
      const formData = new FormData()

      formData.append('input_1', this.state.employees);
      formData.append('input_2', this.state.savings);
      formData.append('input_3', this.state.choice);
      formData.append('input_4', this.state.name);
      formData.append('input_5', this.state.phone);
      formData.append('input_6', this.state.company);

      const request =  await fetch(`https://admin.clearmind.nu/wp-json/gf/v2/forms/5/submissions`, {
        method: 'POST',
        body: formData
      })

      const response = await request.json()
      if (response.is_valid === true) {
        //setStatus('done')
        this.nextStep()
        //setMessage(response.confirmation_message)
        
      } else {
        //setStatus('error')
      }
    } catch (error) {
      //setStatus('error')
    }
  }

  render() {
    const { step, employees, savings, choice, name, company, phone, value } = this.state;
    const values = { employees, savings, choice, name, company, phone, value };
    switch (step) {
      case 1:
        return (
          <>
          <StepsBar step={this.state.step} />
          <StepOne nextStep={this.nextStep} handleChange={this.handleChange} values={values.employees} />
          </>
        );
        break;
      case 2:
        return (
          <>
          <StepsBar step={this.state.step} />
          <StepTwo
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            handleChange={this.handleChange}
            values={values.savings}
          />
          </>
        );
        break;
      case 3:
        return (
          <>
          <StepsBar step={this.state.step} />
          <StepThree
            previousStep={this.previousStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values.choice}
          />
          </>
        );
        break;
      case 4:
        return (
          <>
          <StepsBar step={this.state.step} />
          <StepFour
            nextStep={this.nextStep}
            toStart={this.toStart}
            handleChange={this.handleChange}
            values={values}
            setAmount={this.setAmount}
          />
          </>
        );
        break;
      case 5:
        return (
          <StepFive
            nextStep={this.nextStep}
            toStart={this.toStart}
            handleChange={this.handleChange}
            values={values}
            handleSubmit={this.handleSubmit}
          />
        );
        break;
      case 6:
        return (
          <EndStep
            toStart={this.toStart}
          />
        );
        break;
      default:
        return <StepOne />;
        break;
    }
  }
}

export default SavingTool;
