import React, { Component, useState } from 'react'
import styled from 'styled-components'
import { Form } from 'semantic-ui-react'

// Components
import ButtonBrochure from '../elements/ButtonBrochure'
import TitleDefault from 'components/elements/TitleDefault'

const StyledForm = styled(Form)`
  & label {
    color: ${(props) => props.theme.color.text.light};
  }
`

const Saving = styled.h4`
  font-family: 'Oswald', sans-serif;
  color: ${(props) => props.theme.color.text.light};
  font-size: 40px;
`

function StepFour({ values, toStart, nextStep, setAmount }) {
  //{values.amount = values.employees * values.savings}

  // function handleValue(){
  //   if(values.amount !== ''){
  //     nextStep()
  //     setAmount()
  //   }
  // }

  return (
    <>
      <StyledForm size="big" className="mb-4 d-flex">
        <div className="mx-auto">
          <label className="d-block text-center text-white mb-5">
            Uw minimale besparing is
          </label>
          <Saving className="text-center">
            €{values.employees * values.savings},-
          </Saving>
        </div>
      </StyledForm>
      <div className="footer col-12 row mx-0 mb-4 pt-3 pt-lg-5">
        <div className="mx-auto d-flex flex-wrap">
          <ButtonBrochure
            className="mr-lg-2 mb-3 mb-lg-0 mx-auto mx-lg-0"
            onClick={toStart}
          >
            Bereken opnieuw
          </ButtonBrochure>
          <ButtonBrochure
            className="ml-lg-2 mx-auto mx-lg-0"
            onClick={nextStep}
          >
            Ja, ik wil deze korting
          </ButtonBrochure>
        </div>
      </div>
    </>
  )
}

export default StepFour
